(function (angular, moment, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpDateTimeInput', hippoMinmaxDirective);
    hippoMinmaxDirective.$inject = ['$timeout', '$parse'];
    function hippoMinmaxDirective ($timeout, $parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                ngModelCtrl.$formatters.push(function (modelValue) {
                    ngModelCtrl.$setValidity('datetime', true);
                    if (!modelValue) return;
                    return moment(modelValue).format('MM/DD/YYYY HH:mm:ss');
                });
                ngModelCtrl.$parsers.push(function (input) {
                    ngModelCtrl.$setValidity('datetime', true);
                    if (!input || !input.replace(/[^0-9]/g, '')) {
                        return null;
                    }

                    var date = moment(input, 'MM/DD/YYYY HH:mm:ss', true);
                    if (!date.isValid()) {
                        ngModelCtrl.$setValidity('datetime', false);
                        return null;
                    }

                    return date.toISOString();
                });
            }
        };
    }
})(window.angular, window.moment, window._);
